@import '../../styles/default_variable';

.submit-window {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: space-between;

  width: 80vw;
  max-width: 470px;
  max-height: 340px;
  padding: 50px 0;

  text-align: center;

  background: #f9f9f9;
  border-radius: 20px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.04);
}
