@import '~styles/default_variable';

.desktop-footer {
  display: flex;
  justify-content: space-between;

  width: $desktop-80-perc;
  height: 150px;
  margin-top: 140px;
  margin-bottom: 55px;
}

.column-one {
  width: 10%;

  img {
    width: 54px;
    height: 52px;
  }
}

.column-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.column-content__text {
  cursor: pointer;
  color: $darkgray;

  &:hover {
    color: #786f9c;
  }
}

.column-one,
.column-two,
.column-three,
.column-four {
  display: flex;
  align-items: center;
  padding: 10px;
}

.column-two,
.column-three {
  width: 35%;
  border-left: 1px solid black;
}

.column-three {
  .column-content {
    gap: 15px;
  }
}

.column-four {
  border-left: 1px solid black;
}

.footer__icon {
  cursor: pointer;

  display: flex;
  gap: 10px;
  align-items: center;

  font-size: 24px;

  &::before {
    color: $darkgray;
  }

  &:hover {
    &::before {
      color: $darkviolet;
    }
  }

  p {
    font-size: 14px;

    &:hover {
      color: $darkviolet;
    }
  }
}

@media screen and (max-width: 1439px) {
  .desktop-footer {
    width: 90vw;
  }
}
