@import '~styles/default_variable';

* {
  z-index: 1;

  box-sizing: border-box;
  margin: 0;
  padding: 0;

  border: 0;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

body {
  background: $darkgray;
}
