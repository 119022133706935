$light: rgb(249, 249, 249);
$lightgray: rgb(240, 240, 235);
$darkgray: rgb(99, 99, 99);
$darkviolet: rgb(111, 104, 139);
$darkviolet-text: rgba(111, 104, 139, 0.8);
$shadow-default: rgba(0, 0, 0, 0.04);
$shadow-bold: rgba(0, 0, 0, 0.4);
$desktop-full-screen: 1440px;
$desktop-90-perc: 1326px;
$desktop-80-perc: 1170px;
$desktop-70-perc: 1030px;
$tablet-full-screen: 1024px;
$tablet-90-perc: 944px;
$tablet-80-perc: 824px;
