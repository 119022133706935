@import '~styles/default_variable';

body {
  background: $lightgray;
}

.app-content {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  
  min-height: 100vh;
}

.app-content::before {
  content: ' ';

  position: absolute;

  width: 100%;
  height: 100%;

  opacity: 0.4;
  background: url('~assets/img/noise-3.svg'),
    linear-gradient(0deg, rgba(240, 240, 235, 0.25), rgba(240, 240, 235, 0.25)),
    rgba(255, 255, 255, 0.1);
  background-blend-mode: overlay, normal, overlay;
}

h1 {
  font-family: 'Playfair Display';
  font-size: 52px;
  font-weight: 600;
  font-style: normal;
  line-height: 69px;
  color: $darkviolet-text;
  text-transform: uppercase;

  opacity: 0.8;
}

h2 {
  font-family: 'Playfair Display';
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: $darkviolet;
  text-align: center;
  letter-spacing: 0;
}

p {
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  color: $darkgray;
  letter-spacing: 0.04em;
}

@media screen and (max-width: 1439px) {
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 42px;
    line-height: 56px;
  }
}

@media screen and (max-width: 800px) {
  p {
    line-height: 20px;
  }

  h1 {
    font-size: 32px;
    line-height: 42px;
  }
}
