@import '~styles/default_variable';

.article {
  display: flex;
  flex-direction: column;

  width: 44vw;
  max-width: 570px;
  height: 115px;
}

.article-date {
  font-size: 12px;
}

.article-item__logo {
  flex-shrink: 0;

  width: 52px;
  height: 52px;

  background-size: cover;
  border-radius: 26px;

  transition: 0.2s;
}

.article-item {
  display: flex;
  gap: 20px;

  width: 44vw;
  max-width: 570px;
  height: 90px;
  padding: 20px 24px;

  background: $light;
  border-radius: 20px;
  box-shadow: 4px 4px 10px $shadow-default;

  transition: 0.2s;

  &:hover {
    background-color: rgb(232, 230, 237);

    .article-item__logo {
      background: $darkviolet;
    }
  }
}

.article-item__description {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.article-item__title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.article-item__text {
  font-size: 12px;
  line-height: 15px;
}

@media screen and (max-width: 500px) {
  .article-item,
  .article {
    width: 90vw;
  }

  .articles-title {
    align-items: flex-start;
    align-self: flex-start;
    width: 90vw;
    margin-left: 0;
  }
}
