@import '~styles/default_variable';

.modal-window {
  position: fixed;
  z-index: 100;
  top: 0;

  overflow-y: auto;
  display: flex;

  width: 100%;
  height: 100%;

  background-color: $shadow-bold;
}

.modal__content-wrapper {
  display: flex;
  flex-direction: column;

  margin: auto;

  opacity: 1;
  border-radius: 20px;
}

.modal__content {
  max-width: 80vw;
  max-height: 80vh;
}

.closeIcon {
  cursor: pointer;

  transform: rotate(45deg);

  align-self: flex-end;

  margin-right: -24px;

  font-size: 48px;
}

.closeIcon:hover {
  transform: rotate(45deg) scale(1.1);
}

.closeIcon:active {
  transform: rotate(45deg) scale(1);
}

.scroll-hide {
  overflow: hidden;
  height: 100vh;
}

@media screen and (max-width: 800px) {
  .closeIcon {
    display: none;
  }
}
