@import '~styles/default_variable';

.button-arrow {
  display: flex;

  width: 0;

  font-size: 12px;

  opacity: 0;

  transition: 0.2s;
}

.custom-button {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  width: 300px;
  height: 54px;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  color: $darkviolet;

  background: none;
  border: 1px solid $darkviolet;
  border-radius: 20px;

  &:hover {
    cursor: pointer;

    .button-arrow {
      width: 30px;
      opacity: 1;
    }
  }

  &:active {
    box-shadow: 0 0 0 0 #000;
  }
}

@media screen and (max-width: 1439px) {
  .custom-button {
    width: 240px;
    height: 42px;
  }
}
