@import '~styles/default_variable';

footer {
  display: flex;
  justify-content: center;
  width: 100vw;
}

@media screen and (max-width: 800px) {
  footer {
    margin-top: 100px;
  }
}
