@import '~styles/default_variable';

.faq-item {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;

  border-radius: 20px;
  box-shadow: 4px 4px 10px $shadow-default;
}

.faq-item-question {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  min-height: 72px;
  padding: 20px 40px;

  font-size: 1.25em;

  background: $light;
}

.faq-item-question__title {
  align-self: center;
  justify-self: center;

  width: 90%;

  color: $darkviolet;
  word-wrap: normal;
  white-space: break-spaces;
}

.faq-item-question__plus {
  cursor: pointer;

  transform: rotate(0deg);

  display: flex;
  align-items: center;

  font-size: 2em;
  font-weight: 300;

  transition: 0.5s;

  &.open {
    transform: rotate(135deg);
  }
}

.faq-item__answer {
  max-height: 0;
  background: $light;

  &.visible {
    max-height: 1000px;
    padding: 0 120px 25px 50px;
  }
}

@media screen and (max-width: 1023px) {
  .faq-item-question {
    padding: 20px 50px span {
      margin: 0 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .faq-item-question {
    padding: 20px 40px;

    span {
      margin: 0 5px;
    }
  }

  .faq-item__answer.faq-item__answer.visible {
    padding-right: 70px;
  }
}

@media screen and (max-width: 500px) {
  .faq-item-question,
  .faq-item__answer.visible {
    padding: 20px;
  }

  .faq-item-question__title {
    font-size: 16px;
  }
}
