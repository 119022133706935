@import '~styles/default_variable';

.mobile-menu {
  position: fixed;
  bottom: 0;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100vw;
  height: 100%;
  max-height: 0;

  opacity: 0.95;
  background-color: $darkviolet;

  transition: 0.5s;

  &.visible {
    max-height: 500px;
    padding: 0 10px;
    transition: 0.8s;
  }
}

.mobile-menu__social-networks {
  display: flex;
  gap: 10px;
}

.footer-drop-down {
  position: fixed;
  bottom: 60px;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100vw;
  height: auto;
  max-height: 0;
  padding: 0 10px;

  color: $light;

  opacity: 0.95;
  background-color: $darkviolet;
  border-radius: 5px 5px 0 0;

  transition: 0.2s;

  .navlink {
    display: flex;
    align-items: center;

    width: 150px;
    height: 30px;

    font-weight: 500;
    color: $light;
    text-transform: uppercase;
  }

  &.visible {
    max-height: 500px;
    padding: 0 10px;
    transition: 0.8s;
  }
}

@media screen and (max-width: 800px) {
  .mobile-menu {
    width: 100vw;
    height: 60px;
    margin: 70px 0 0;

    img {
      width: 200px;
    }

    span {
      font-size: 24px;
    }

    .icon-menu,
    .icon-cross {
      width: 30px;
      font-size: 24px;

      &::before {
        color: $light;
      }
    }
  }
}
