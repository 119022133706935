@font-face {
  font-family: icomoon;
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url('./icomoon.eot?b6uhgz');
  src: url('./icomoon.eot?b6uhgz#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?b6uhgz') format('truetype'), url('./icomoon.woff?b6uhgz') format('woff'),
    url('./icomoon.svg?b6uhgz#icomoon') format('svg');
}

[class^='icon-']::before,
[class*=' icon-'] ::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cross::before {
  content: '\e905';
}

.icon-b17::before {
  content: '\e904';
  color: #fff;
}

.icon-menu::before {
  content: '\e906';
}

.icon-arrow::before {
  content: '\e903';
}

.icon-instagram::before {
  content: '\e900';
  color: #fff;
}

.icon-telegram::before {
  content: '\e901';
  color: #fff;
}

.icon-whatsapp::before {
  content: '\e902';
  color: #fff;
}
