@import '~styles/default_variable';

header {
  position: absolute;
  z-index: 2;
  top: 15px;
  width: 100vw;
}

.header-drop-down {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 10px;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 120px;
  max-height: 0;

  color: $light;

  opacity: 1;
  background-color: $darkviolet;
  border-radius: 10px;

  transition: 0s;

  .navlink {
    display: flex;
    align-items: center;

    width: fit-content;
    height: 32px;
    padding: 6px 10px;

    font-weight: 500;
    color: $light;
    text-transform: uppercase;

    border-radius: 20px;

    &.active {
      font-weight: 500;
      color: $light;
      border: 1px solid $light;
    }
  }

  &.visible {
    z-index: 1;

    width: 200px;
    max-height: 300px;
    padding: 10px 20px;

    opacity: 0.95;

    transition: 0.5s;
  }
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 1170px;
  margin: 0 auto;

  filter: contrast(0.9);

  img {
    width: 170px;
    height: 60px;
  }

  nav {
    display: flex;
    gap: 10px;
    color: #1a1919;

    .navlink {
      cursor: pointer;

      display: flex;
      align-items: center;

      height: 32px;
      padding: 6px 10px;

      font-size: 12px;
      font-weight: 500;
      color: $darkviolet;
      text-decoration: none;
      text-transform: uppercase;

      border-radius: 20px;

      &:hover {
        color: $darkviolet;
      }

      &.active {
        font-weight: 500;
        color: $darkviolet;
        border: 1px solid $darkviolet;
      }
    }
  }
}

.burger-menu__icon {
  cursor: pointer;
  z-index: 2;
  font-size: 24px;
  opacity: 0.8;

  &.light {
    color: $light;
  }
}

@media screen and (max-width: 1439px) {
  .header-content {
    width: 90vw;

    nav {
      gap: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  header {
    top: 5px;
  }

  .header-content {
    img {
      width: 120px;
      height: 42px;
    }
  }
}
