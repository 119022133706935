@import '~styles/default_variable';

.go-to-main-link {
  display: flex;
  gap: 10px;
  align-items: center;

  &::before {
    transform: rotate(180deg);
    color: $darkviolet;
  }
}