@import '~styles/default_variable';

.preloader {
  position: fixed;
  z-index: 1000;
  top:0%;
  left: 0%;

  width: 100vw;
  height: 100vh;

  opacity: 1;

  svg {
    will-change: transform;

    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: scale(1.4);

    margin-top: -70px;
    margin-left: -100px;
  }
}

.preloader__background {
  width: 100%;
  height: 100%;
  background-color: $lightgray;
  backdrop-filter: 'blur';
}

.preloader__background::before {
  content: ' ';

  position: absolute;

  width: 100%;
  height: 100%;

  opacity: 0.4;
  background: url('~assets/img/noise-3.svg'),
    linear-gradient(0deg, rgba(240, 240, 235, 0.25), rgba(240, 240, 235, 0.25)),
    rgba(255, 255, 255, 0.1);
  background-blend-mode: overlay, normal, overlay;
}
